"use client";

import { MultiSelect, MultiSelectProps, createPolymorphicComponent } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { CaretDown, CaretUp } from "@phosphor-icons/react";
import { forwardRef } from "react";

// https://mantine.dev/guides/polymorphic/
interface CustomMultiSelectProps extends MultiSelectProps {
  onChange?: (value: string[]) => void;
}

const CustomMultiSelect = createPolymorphicComponent<"select", CustomMultiSelectProps>(
  forwardRef<HTMLInputElement, CustomMultiSelectProps>(({ onChange, ...props }, ref) => {
    const [dropdownOpened, { open: openDropdown, close: closeDropdown }] = useDisclosure(false);

    const handleChange = (value: string[]) => {
      if (onChange) {
        onChange(value);
      }
    };

    return (
      <MultiSelect
        component="input"
        {...props}
        checkIconPosition="right"
        onDropdownClose={closeDropdown}
        onDropdownOpen={openDropdown}
        rightSection={dropdownOpened ? <CaretUp /> : <CaretDown />}
        ref={ref}
        onChange={handleChange}
      />
    );
  }),
);

export { CustomMultiSelect as MultiSelect };
