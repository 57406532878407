"use client";

import { ComboboxItem, Select, SelectProps, createPolymorphicComponent } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { CaretDown, CaretUp } from "@phosphor-icons/react";
import { forwardRef } from "react";

// https://mantine.dev/guides/polymorphic/
interface CustomSelectProps extends SelectProps {
  onChange?: (value: string | null, option: ComboboxItem) => void;
}

const CustomSelect = createPolymorphicComponent<"select", CustomSelectProps>(
  forwardRef<HTMLInputElement, CustomSelectProps>(({ onChange, ...props }, ref) => {
    const [dropdownOpened, { open: openDropdown, close: closeDropdown }] = useDisclosure(false);

    const handleChange = (value: string | null, option: ComboboxItem) => {
      if (onChange) {
        onChange(value, option);
      }
    };

    return (
      <Select
        component="input"
        {...props}
        checkIconPosition="right"
        onDropdownClose={closeDropdown}
        onDropdownOpen={openDropdown}
        rightSection={dropdownOpened ? <CaretUp /> : <CaretDown />}
        ref={ref}
        onChange={handleChange}
      />
    );
  }),
);

export { CustomSelect as Select };
