import { ButtonProps, MantineStyleProp, createPolymorphicComponent } from "@mantine/core";
import { Button } from "@syde-ui";
import { forwardRef } from "react";

// https://mantine.dev/guides/polymorphic/
interface GradientButtonProps extends ButtonProps {
  label?: string;
  mode?: "black";
}

const GradientButton = createPolymorphicComponent<"button", GradientButtonProps>(
  forwardRef<HTMLButtonElement, GradientButtonProps>(({ mode, ...props }, ref) => {
    let style: MantineStyleProp = props.style;
    if (props.variant === undefined) {
      style = {
        ...style,
        background:
          mode === "black"
            ? "linear-gradient(0deg, #20232D 0%, #242628 100%), linear-gradient(180deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0) 100%)"
            : "linear-gradient(0deg, var(--mantine-color-blue-6) 0%, var(--mantine-color-blue-5) 100%), linear-gradient(180deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0) 100%)",
      };
    }
    return <Button component="button" {...props} style={style} ref={ref} />;
  }),
);

export { GradientButton };
