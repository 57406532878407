import { Button, ButtonProps, createPolymorphicComponent } from "@mantine/core";
import { forwardRef } from "react";

// https://mantine.dev/guides/polymorphic/
interface CustomButtonProps extends ButtonProps {}

const CustomButton = createPolymorphicComponent<"button", CustomButtonProps>(
  forwardRef<HTMLButtonElement, CustomButtonProps>(({ ...props }, ref) => {
    return <Button component="button" {...props} ref={ref} />;
  }),
);

export { CustomButton as Button };
