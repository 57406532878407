import { Paper } from "@mantine/core";
import { Icon } from "@phosphor-icons/react";
import classes from "./GradientCircle.module.css";

interface GradientCircleProps {
  icon: Icon;
}

const GradientCircle: React.FC<GradientCircleProps> = ({ icon: CustomIcon }) => {
  return (
    <Paper
      style={{
        width: 88,
        height: 88,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 44,
        background: `linear-gradient(180deg, var(--mantine-color-gray-1) 0%, var(--mantine-color-white) 100%`,
      }}
    >
      <Paper
        shadow={"xs"}
        style={{ width: 56, height: 56, display: "flex", alignItems: "center", justifyContent: "center", borderRadius: 28 }}
      >
        <CustomIcon size={28} className={classes.icon} />
      </Paper>
    </Paper>
  );
};

export { GradientCircle };
