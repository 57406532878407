import { Button } from "@syde-ui";
import Link from "next/link";

export function ContactSalesCTA({ size = "xs" }: { size?: string }) {
  return (
    <Button
      size={size}
      px="xs"
      component={Link}
      href="https://hubs.ly/Q02Q8nPs0"
    >
      Contact Sales
    </Button>
  );
}

{
  /* <Button
size="xs"
color={"var(--mantine-color-gray-0)"}
c="dark"
px={"xs"}
component={Link}
href={
  "https://protectai.com/cs/c/?cta_guid=cd2c1f2b-63bc-459c-9f2c-1b7ac303483e&signature=AAH58kGBmr0J9mBpWbC2WJyMze6yjn8epg&portal_id=22563925&pageId=174528848261&placement_guid=aee5e6ca-2507-4840-9427-4f835d5be2bb&click=1a523fed-e3d3-4c90-8c0a-1e842d7368dd&redirect_url=APefjpGOAZy3-lD3S_abXcmsB_kYbxoO84MTj91c0Z7oaH2yvGnnMjP2VrW0jhFsuAx4j39In3OKuf3JPvbH_nZzQhB5R1Se7O2T-QGdcVwUH42Ezp6FNiHoYVqAku3LMJmBjVamoQV8&hsutk=1a61b4b95b23ddadb363098ff290af3c&canon=https%3A%2F%2Fprotectai.com%2Frecon&ts=1727082933352&__hstc=79449099.1a61b4b95b23ddadb363098ff290af3c.1727082933097.1727082933097.1727082933097.1&__hssc=79449099.1.1727082933097&__hsfp=776682005&contentType=standard-page"
}
>
Contact Sales
</Button> */
}
