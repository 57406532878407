import { ActionIcon, Center, Container, Flex, Group, rem, Text, Title } from "@mantine/core";
import { ContactSalesCTA } from "@syde/components/ContactSalesCTA/ContactSalesCTA";
import { IconBrandLinkedin, IconBrandSlack, IconBrandX, IconBrandYoutubeFilled, IconMail } from "@tabler/icons-react";
import Image from "next/image";
import Link from "next/link";
import classes from "./Footer.module.css";

export function Footer() {
  return (
    <footer className={classes.footer}>
      <Container size={"xl"} py={"md"}>
        <Center pb="calc(var(--mantine-spacing-md) * 3)">
          {/* <Container className={classes.inner}> */}
          <Flex direction={"column"} align={"center"}>
            <div className={classes.logo}>
              <Image src={"/images/pai_logo.svg"} height={56} width={56} alt="pai_text" style={{ width: 56, height: 56 }} />
            </div>

            <Title order={3} c="white" pb="xs" pt="lg" fw={500}>
              Let's get started on something great
            </Title>
            <Text size="xs" c="var(--text-sec-dark)" pb="xl">
              Join over 4,000+ startups already growing with Protect AI.
            </Text>
            <Group>
              <ContactSalesCTA size="xs" />
            </Group>
          </Flex>
          {/* </Container> */}
        </Center>
        <div className={classes.afterFooter}>
          <Text c="var(--text-sec-dark)" size="sm">
            © 2024 Protect AI. All rights reserved.
          </Text>

          <Group gap={0} justify="flex-end" wrap="nowrap">
            <ActionIcon
              size="lg"
              color="var(--text-sec-dark)"
              variant="subtle"
              component={Link}
              href={
                "https://mlsecops.slack.com/join/shared_invite/zt-24f8mmm45-Qc9qfZVxzBL4J5vcuLjaVw?__hstc=233546881.a739520008d547d7dc4688d34de58dd5.1696968351919.1702313654233.1702318226856.6&__hssc=233546881.640.1702318226856&__hsfp=2365361157&hsCtaTracking=6a0b2405-a9c0-4d51-ab47-3e4dc3de8620%7C3235b186-322d-4fee-a140-ca15cbfb4eba#/shared-invite/email"
              }
            >
              <IconBrandSlack style={{ width: rem(18), height: rem(18) }} stroke={1.5} />
            </ActionIcon>
            <ActionIcon
              size="lg"
              color="var(--text-sec-dark)"
              variant="subtle"
              component={Link}
              href={"https://www.linkedin.com/company/protect-ai"}
            >
              <IconBrandLinkedin style={{ width: rem(18), height: rem(18) }} stroke={1.5} />
            </ActionIcon>
            <ActionIcon
              size="lg"
              color="var(--text-sec-dark)"
              variant="subtle"
              component={Link}
              href={"https://www.youtube.com/channel/UCdEDCni1cEJb_f7IjPQPsig"}
            >
              <IconBrandYoutubeFilled style={{ width: rem(18), height: rem(18) }} stroke={1.5} />
            </ActionIcon>
            <ActionIcon size="lg" color="var(--text-sec-dark)" variant="subtle" component={Link} href={"mailto:community@protectai.com"}>
              <IconMail style={{ width: rem(18), height: rem(18) }} stroke={1.5} />
            </ActionIcon>
            <ActionIcon size="lg" color="var(--text-sec-dark)" variant="subtle" component={Link} href={"https://twitter.com/ProtectAICorp"}>
              <IconBrandX style={{ width: rem(18), height: rem(18) }} stroke={1.5} />
            </ActionIcon>
          </Group>
        </div>
      </Container>
    </footer>
  );
}
