"use client";

import { Info } from "@phosphor-icons/react";
import { Box, Group, HoverCard, Stack, Text } from "../index";

interface DuoTextProps {
  label: string;
  description: string;
  size?: "sm" | "md" | "lg" | "xs" | "lg" | "xl";
  desSize?: "sm" | "md" | "lg" | "xs" | "lg" | "xl";
  hoverText?: string;
}
const DuoText = ({ label, description, size = "md", desSize, hoverText, ...props }: DuoTextProps | any) => {
  return (
    <Stack gap={0} {...props}>
      <Group align="center" gap={4}>
        <Text size={size} fw={500}>
          {label}
        </Text>
        {hoverText && (
          <Box pb={2}>
            <HoverCard width={600} withArrow shadow="md" openDelay={200} closeDelay={0}>
              <HoverCard.Target>
                <Info weight="fill" color="gray" size={14} />
              </HoverCard.Target>
              <HoverCard.Dropdown>
                <Text size="sm">{hoverText}</Text>
              </HoverCard.Dropdown>
            </HoverCard>
          </Box>
        )}
      </Group>
      <Text size={desSize || size} c="gray">
        {description}
      </Text>
    </Stack>
  );
};

export { DuoText };
