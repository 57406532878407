import { createPolymorphicComponent } from "@mantine/core";
import { forwardRef } from "react";

// https://mantine.dev/guides/polymorphic/
interface CustomFormProps {
  buttonName?: string | undefined;
  onSubmit?: () => void;
}

const CustomForm = createPolymorphicComponent<"form", CustomFormProps>(
  forwardRef<HTMLFormElement, CustomFormProps>(({ onSubmit, buttonName, ...props }, ref) => {
    const handleOnSubmit = (e: any) => {
      e.preventDefault();
      if (onSubmit) {
        onSubmit();
      }
    };

    return <form {...props} ref={ref} onSubmit={handleOnSubmit} />;
  }),
);

export { CustomForm as Form };
