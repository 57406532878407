"use client";
import { Box, Container, Group } from "@mantine/core";
import { Button } from "@syde-ui";
import { ContactSalesCTA } from "@syde/components/ContactSalesCTA/ContactSalesCTA";
import { signOut } from "next-auth/react";
import Image from "next/image";
import Link from "next/link";
import classes from "./Header.module.css";

export function Header({ session }: any) {
  const onLogout = async () => {
    await signOut({ callbackUrl: "/" });
  };

  return (
    <Box className={classes.headerSticky}>
      <header className={classes.header}>
        <Container size={"xl"} py={"md"}>
          <Group justify="space-between">
            <Link href="/">
              <Image src={"/images/pai_white_text.svg"} height={36} width={200} alt="pai_text" />
            </Link>
            <Group visibleFrom="sm" gap={"xs"}>
              {!!session && (
                <Button
                  variant="white"
                  size="xs"
                  px="xs"
                  onClick={onLogout}
                  className="text-sm font-medium transition-colors hover:text-primary"
                >
                  Logout
                </Button>
              )}
              <ContactSalesCTA />
            </Group>
          </Group>
        </Container>
      </header>
    </Box>
  );
}
